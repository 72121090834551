// file upload
export const FILE_UPLOAD_REQUEST = "FILE_UPLOAD_REQUEST";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_FAILED = "FILE_UPLOAD_FAILED";

export const LOGIN_USER = "LOGIN_USER";
export const TOGGLE_FULL_SCREEN = "TOGGLE_FULL_SCREEN";
export const OTP_VERIFY = "OTP_VERIFY";
export const FORGET_PASSWORD_INFO = "FORGET_PASSWORD_INFO";

// customers - user list
export const CUSTOMER_LIST = "CUSTOMER_LIST";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const EDIT_CUSTOMER_NAME = "EDIT_CUSTOMER_NAME";
export const UNPAID_CUSTOMER_LIST = "UNPAID_CUSTOMER_LIST";

// Quick Order
export const QUICK_ORDER = "QUICK_ORDER";
export const ORDER_LIST = "ORDER_LIST";
export const GET_ORDERBY_ID = "GET_ORDERBY_ID";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";

export const CUSTOMER_BY_ID_LIST = "CUSTOMER_BY_ID_LIST";
export const CUSTOMER_DETAILS_LIST = "CUSTOMER_DETAILS_LIST";

export const SELECTED_LIVE_ORDER = "SELECTED_LIVE_ORDER";
export const SELECTED_LIVE_ORDER_NULL = "SELECTED_LIVE_ORDER_NULL";
export const BYPASS_PAYMENT_LIST = "BYPASS_PAYMENT_LIST";
export const CHANGE_TABLE_LIST = "CHANGE_TABLE_LIST";

// Menu
export const MENU_LIST = "MENU_LIST";
export const UPDATE_ITEM_LIST = "UPDATE_ITEM_LIST";

// Notification
export const GET_NOTIFICATION_LIST = "GET_NOTIFICATION_LIST";
export const HEADER_NOTIFICATION_OFF = "HEADER_NOTIFICATION_OFF";
export const LIVE_ORDER_TAB_COUNTING = "LIVE_ORDER_TAB_COUNTING";
export const TAB_COUNTERS_UPDATED = "TAB_COUNTERS_UPDATED";

// user logout
export const USER_LOGOUT = "USER_LOGOUT";

// walk in customer
export const GET_WALK_IN_CUSTOMER_LIST = "GET_WALK_IN_CUSTOMER_LIST";
export const WALK_IN_RIGHT_CHANGE_SCREEN = "WALK_IN_RIGHT_CHANGE_SCREEN";
export const SELECT_GROUP_ORDER_DATA = "SELECT_GROUP_ORDER_DATA";
export const SELECT_GROUP_DATA = "SELECT_GROUP_DATA";
export const OPEN_GROUP_SIDE_DRAWER = "OPEN_GROUP_SIDE_DRAWER";
export const RESET_WALK_IN_CUSTOMER = "RESET_WALK_IN_CUSTOMER";
export const IS_ITEMS_CHECKOUT = "IS_ITEMS_CHECKOUT";
export const IS_ALL_ITEMS_SELECTED = "IS_ALL_ITEMS_SELECTED";
export const SELECTED_ITEMS_LIST = "SELECTED_ITEMS_LIST";

// split Bill
export const SELECTED_CUSTOMER_LIST = "SELECTED_CUSTOMER_LIST";
export const GET_CUSTOMERS_BILL_LIST = "GET_CUSTOMERS_BILL_LIST";
export const GET_ITEMS_LIST = "GET_ITEMS_LIST";

export const ACCEPTANCE_STATUS = "ACCEPTANCE_STATUS";
export const LIVE_ORDER = "LIVE_ORDER";
export const ORDER_GENERATED = "ORDER_GENERATED";
export const ORDER_UPDATED = "ORDER_UPDATED";
export const ORDER_CANCELLED = "ORDER_CANCELLED";
export const PAYMENT_USER_DETAILS = "PAYMENT_USER_DETAILS";
export const PAYMENT_USER_STATUS = "PAYMENT_USER_STATUS";
export const PAYMENT_STATUS_UPDATED = "PAYMENT_STATUS_UPDATED";
export const ANALYTICS_SUMMARY = "ANALYTICS_SUMMARY";
export const ANALYTICS_LIST = "ANALYTICS_LIST";
export const SYSTEM_STATUS_CHANGE = "SYSTEM_STATUS_CHANGE";
export const PLAY_SOUND = "PLAY_SOUND";
export const TABLE_FOR_SOLD_ITEMS = "TABLE_FOR_SOLD_ITEMS";
export const CATEGORY_NAMES = "CATEGORY_NAMES";
export const TOTAL_ORDER_CHART = "TOTAL_ORDER_CHART";
export const TOP_SELLING_CHART = "TOP_SELLING_CHART";
export const LEAST_SELLING_CHART = "LEAST_SELLING_CHART";
export const GET_QUEUE_ORDER = "GET_QUEUE_ORDER";

export const TOTAL_ORDER_REQUEST_PARAMETERS = "TOTAL_ORDER_REQUEST_PARAMETERS";
export const TOP_SELLING_REQUEST_PARAMETERS = "TOP_SELLING_REQUEST_PARAMETERS";
export const LEAST_SELLING_REQUEST_PARAMETERS =
  "LEAST_SELLING_REQUEST_PARAMETERS";

export const WEEKLY_SETTINGS = "WEEKLY_SETTINGS";
export const BOT_MESSAGES = "BOT_MESSAGES";
export const GLOBAL_CONFIGURATION = "GLOBAL_CONFIGURATION";
export const MESSAGES_EXCHANGED = "MESSAGES_EXCHANGED";

export const ORGANIZATION_EMAIL_CONFIG = "ORGANIZATION_EMAIL_CONFIG";
export const IS_SKIPPED = "IS_SKIPPED";

export const GET_BAR_TENDER_DATA = "GET_BAR_TENDER_DATA";
export const SELECTED_BAR_TENDER = "SELECTED_BAR_TENDER";

export const GET_ALL_TIPS = "GET_ALL_TIPS";
export const IS_OPEN_BAR_TENDER_RIGHT = "IS_OPEN_BAR_TENDER_RIGHT";
export const VIEW_ALL_DAY_TIPS = "VIEW_ALL_DAY_TIPS";
export const IS_OPEN_LIST_OF_TENDER = "IS_OPEN_LIST_OF_TENDER";
export const TENDER_WITH_TIP_LIST = "TENDER_WITH_TIP_LIST";
export const UPDATE_BAR_TENDER_SHIFT = "UPDATE_BAR_TENDER_SHIFT";

export const IS_LIVE_ORDER_OPEN = "IS_LIVE_ORDER_OPEN";
export const CURRENT_SELECTED_MESSAGE_CHANNEL =
  "CURRENT_SELECTED_MESSAGE_CHANNEL";

export const SET_TABLE_NUMBER = "SET_TABLE_NUMBER";
export const RESET_TABLE_NUMBER = "RESET_TABLE_NUMBER";
export const GET_USER_LIST_FROM_TABLE_SELECTION =
  "GET_USER_LIST_FROM_TABLE_SELECTION";

export const LEFT_CHANGE_SCREEN = "LEFT_CHANGE_SCREEN";
export const RIGHT_CHANGE_SCREEN = "RIGHT_CHANGE_SCREEN";
export const GET_TWO_IN_ONE_POST_CATEGORIES = "GET_TWO_IN_ONE_POST_CATEGORIES";
export const SET_SELECTED_CATEGORY_OBJECT = "SET_SELECTED_CATEGORY_OBJECT";
export const SET_SELECTED_CUSTOMER = "SET_SELECTED_CUSTOMER";
export const GET_TWO_IN_ONE_ITEM_LIST_BASED_ON_CATEGORY =
  "GET_TWO_IN_ONE_ITEM_LIST_BASED_ON_CATEGORY";
export const RESET_TWO_IN_ONE_STATE = "RESET_TWO_IN_ONE_STATE";
export const SET_SELECTED_ITEM_LIST = "SET_SELECTED_ITEM_LIST";

export const ORDER_HISTORY_PAGINATED_CALL = "ORDER_HISTORY_PAGINATED_CALL";
export const ON_SCROLL_PAGINATED_CALL = "ON_SCROLL_PAGINATED_CALL";

export const GET_ALL_MASTER_STATION_LIST = "GET_ALL_MASTER_STATION_LIST";

export const GET_STATION_SPECIFIC_LIST = "GET_STATION_SPECIFIC_LIST";

export const GET_MANAGE_STATION_LIST = "GET_MANAGE_STATION_LIST";

// Printers
export const GET_MANAGE_PRINTERS_LIST = "GET_MANAGE_PRINTERS_LIST";
export const GET_MANAGE_UNNAMED_PRINTERS_LIST =
  "GET_MANAGE_UNNAMED_PRINTERS_LIST";

// Floors
export const RESET_TAKE_ORDER_SCREEN_STATE = "RESET_TAKE_ORDER_SCREEN_STATE";
export const FLOOR_LIST = "FLOOR_LIST";
export const CHANGE_FLOOR_LIST = "FLOOR_LIST";
export const UPDATE_FLOOR = "UPDATE_FLOOR";
export const DELETE_FLOOR = "DELETE_FLOOR";
export const FLOOR_ACTIVE_SCREEN = "FLOOR_ACTIVE_SCREEN";
export const FLOOR_BYID_LIST = "FLOOR_BYID_LIST";
export const TAKE_ORDER_FLOOR_LIST = "TAKE_ORDER_FLOOR_LIST";
export const MANAGE_FLOOR_ID = "MANAGE_FLOOR_ID";

// By Pass
export const BP_RIGHT_CHANGE_SCREEN = "BP_RIGHT_CHANGE_SCREEN";
export const SELECT_TABLE_ORDER_DATA = "SELECT_TABLE_ORDER_DATA";
export const SELECT_ORDER_DATA = "SELECT_ORDER_DATA";
export const RESET_BY_PASS_STATE = "RESET_BY_PASS_STATE";

// Items
export const GET_VARIOUS_ITEMS_LIST = "GET_VARIOUS_ITEMS_LIST";
export const CLEAR_INFINITE_PAGE_DATA = "CLEAR_INFINITE_PAGE_DATA";

// bar menu collapse index store
export const BAR_MENU_COLLAPSE_INDEX = "BAR_MENU_COLLAPSE_INDEX";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_SUB_CATEGORY = "UPDATE_SUB_CATEGORY";
