import * as ActionConstants from "../constants/ReduxConstants";
import { FORGOT_PASSWORD_STATUS } from "../constants/AppConstants";

export const forgotPasswordInfo = (state = {}, action) => {
  switch (action.type) {
    case ActionConstants.FORGET_PASSWORD_INFO:
      return action.userData;

    default:
      return state;
  }
};

export const forgotPassword = (
  state = FORGOT_PASSWORD_STATUS.IDEAL,
  action
) => {
  switch (action.type) {
    case ActionConstants.OTP_VERIFY:
      return action.data;

    default:
      return state;
  }
};
