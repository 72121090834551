import * as ActionConstants from "../constants/ReduxConstants";

export const paymentUserInfo = (state = {}, action) => {
  switch (action.type) {
    case ActionConstants.PAYMENT_USER_DETAILS:
      return action.data;

    default:
      return state;
  }
};

export const paymentUserStatus = (state = {}, action) => {
  switch (action.type) {
    case ActionConstants.PAYMENT_USER_STATUS:
      return action.data;

    default:
      return state;
  }
};
