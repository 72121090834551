import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { loadingBarMiddleware } from "react-redux-loading-bar";
import mainReducer from "../reducers/MainReducer";

export default function configureStore() {
  return createStore(
    mainReducer,
    applyMiddleware(
      thunk,
      loadingBarMiddleware({
        promiseTypeSuffixes: ["REQUEST", "SUCCESS", "FAILURE"],
      })
    )
  );
}
