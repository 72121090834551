import * as ActionConstants from "../constants/ReduxConstants";

export const isFullScreen = (state = false, action) => {
  switch (action.type) {
    case ActionConstants.TOGGLE_FULL_SCREEN:
      return action.isFullScreen;

    default:
      return state;
  }
};
