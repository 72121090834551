import React, { lazy } from "react";
import "./App.css";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";

// import Routes from "./routes";
import { Suspense } from "react";
import Loader from "./components/Dashboard/CommonComponents/Loader";

const Routes = lazy(() => import("./routes"));

const defaultTheme = createMuiTheme();

const App = (props) => {
  return (
    <MuiThemeProvider theme={THEME}>
      <Suspense fallback={<Loader />}>
        <Routes />
      </Suspense>
    </MuiThemeProvider>
  );
};
const THEME = createMuiTheme({
  typography: {
    fontFamily: `"Maven Pro", 'sans-serif`,
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  overrides: {
    MuiToolbar: {
      gutters: {
        [defaultTheme.breakpoints.up("sm")]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
  },
});
export default App;
