import * as ActionConstants from "../constants/ReduxConstants";

let initialState = {
  historyOrderData: {
    docs: [],
    total: 0,
    limit: 15,
    page: 1,
    pages: 1,
  },
};
export const orderHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.ORDER_LIST:
      return {
        ...state,
        historyOrderData: {
          ...state.historyOrderData,
          docs: state.historyOrderData.docs.concat(
            action.data.docs.filter(
              (value) => !state.historyOrderData.docs.includes(value)
            )
          ),
          page: action?.data?.page,
          limit: action?.data?.limit,
          pages: action?.data?.pages,
          total: action?.data?.total,
        },
      };
    case ActionConstants.DELETE_ORDER:
      return {
        ...state,
        historyOrderData: {
          ...state.historyOrderData.docs,
          docs: state.historyOrderData.docs.filter(
            (item, index) => item._id !== action.data
          ),
        },
      };

    case ActionConstants.CLEAR_INFINITE_PAGE_DATA:
      return initialState;
    default:
      return state;
  }
};

export const orderDetails = (state = [], action) => {
  switch (action.type) {
    case ActionConstants.GET_ORDERBY_ID:
      return action.data;
    default:
      return state;
  }
};

export const selectedLiveOrder = (state = null, action) => {
  switch (action.type) {
    case ActionConstants.SELECTED_LIVE_ORDER:
      return action.data;

    case ActionConstants.SELECTED_LIVE_ORDER_NULL:
      return null;

    default:
      return state;
  }
};
