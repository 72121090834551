import * as ActionConstants from "../constants/ReduxConstants";

export const barTenderList = (state = [], action) => {
  switch (action.type) {
    case ActionConstants.GET_BAR_TENDER_DATA:
      return action.data;

    default:
      return state;
  }
};

export const barStationList = (state = [], action) => {
  switch (action.type) {
    case ActionConstants.GET_STATION_SPECIFIC_LIST:
      return action.data;

    default:
      return state;
  }
};

export const selectedBarTender = (state = "", action) => {
  switch (action.type) {
    case ActionConstants.SELECTED_BAR_TENDER:
      return action.data;

    default:
      return state;
  }
};

export const isOpenBarTenderRight = (state = false, action) => {
  switch (action.type) {
    case ActionConstants.IS_OPEN_BAR_TENDER_RIGHT:
      return action.data;

    default:
      return state;
  }
};

export const isOpenListOfTender = (state = false, action) => {
  switch (action.type) {
    case ActionConstants.IS_OPEN_LIST_OF_TENDER:
      return action.data;

    default:
      return state;
  }
};

export const tendersWithTipList = (state = false, action) => {
  switch (action.type) {
    case ActionConstants.TENDER_WITH_TIP_LIST:
      return action.data;

    default:
      return state;
  }
};
