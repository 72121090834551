import * as ActionConstants from "../constants/ReduxConstants";

export const tableForSoldItems = (state = [], action) => {
  switch (action.type) {
    case ActionConstants.TABLE_FOR_SOLD_ITEMS:
      return action.data;

    default:
      return state;
  }
};

export const categoryNames = (state = [], action) => {
  switch (action.type) {
    case ActionConstants.CATEGORY_NAMES:
      return action.data;

    default:
      return state;
  }
};

export const totalOrderChart = (state = {}, action) => {
  let config = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "category",
    },
    yAxis: [
      {
        title: {
          text: "Total Amount(in €)",
        },
      },
      {
        title: {
          text: "",
        },
        opposite: true,
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      pointFormat: "<b>€{point.y:.1f}</b>",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "€{point.y:.1f}",
        },
      },
    },
    colors: ["#3C9FFA"],
  };

  switch (action.type) {
    case ActionConstants.TOTAL_ORDER_CHART:
      return Object.assign(config, {
        series: [
          {
            data: action.data,
          },
        ],
      });

    default:
      return state;
  }
};

export const topSellingChart = (state = {}, action) => {
  let config = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: "",
    },
    tooltip: {
      pointFormat: "<b>{point.percentage:.1f} %</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        cursor: "pointer",
        dataLabels: {
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          connectorColor: "silver",
        },
        showInLegend: true,
      },
    },
    credits: {
      enabled: false,
    },
    colors: [
      "#3C9FFA",
      "#FA973C",
      "#34CA9E",
      "#49AF1A",
      "#285524",
      "#F133AC",
      "#213318",
      "#1B70DC",
      "#6E752E",
      "#AA0752",
    ],
  };

  switch (action.type) {
    case ActionConstants.TOP_SELLING_CHART:
      return Object.assign(config, {
        series: [
          {
            data: action.data,
          },
        ],
      });

    default:
      return state;
  }
};

export const leastSellingChart = (state = {}, action) => {
  let config = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: "",
    },
    tooltip: {
      pointFormat: "<b>{point.percentage:.1f} %</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        cursor: "pointer",
        dataLabels: {
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          connectorColor: "silver",
        },
        showInLegend: true,
      },
      series: {
        events: {
          legendItemClick: function (e) {
            e.preventDefault();
            return false;
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    colors: [
      "#3C9FFA",
      "#FA973C",
      "#34CA9E",
      "#49AF1A",
      "#285524",
      "#F133AC",
      "#213318",
      "#1B70DC",
      "#6E752E",
      "#AA0752",
    ],
  };

  switch (action.type) {
    case ActionConstants.LEAST_SELLING_CHART:
      return Object.assign(config, {
        series: [
          {
            data: action.data,
          },
        ],
      });

    default:
      return state;
  }
};

export const totalOrderRequestParameters = (state = {}, action) => {
  switch (action.type) {
    case ActionConstants.TOTAL_ORDER_REQUEST_PARAMETERS:
      return action.data;

    default:
      return state;
  }
};

export const topSellingRequestParameters = (state = {}, action) => {
  switch (action.type) {
    case ActionConstants.TOP_SELLING_REQUEST_PARAMETERS:
      return action.data;

    default:
      return state;
  }
};

export const leastSellingRequestParameters = (state = {}, action) => {
  switch (action.type) {
    case ActionConstants.LEAST_SELLING_REQUEST_PARAMETERS:
      return action.data;

    default:
      return state;
  }
};
