import * as ActionConstants from "../constants/ReduxConstants";

export const acceptanceStatus = (state = {}, action) => {
  switch (action.type) {
    case ActionConstants.ACCEPTANCE_STATUS:
      return action.data;

    default:
      return state;
  }
};
