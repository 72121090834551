import * as ActionConstants from "../constants/ReduxConstants";

export const tipList = (state = [], action) => {
  switch (action.type) {
    case ActionConstants.GET_ALL_TIPS:
      return action.data;

    default:
      return state;
  }
};

export const allDayTipList = (state = [], action) => {
  switch (action.type) {
    case ActionConstants.VIEW_ALL_DAY_TIPS:
      return action.data;

    default:
      return state;
  }
};
