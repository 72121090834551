import * as ActionConstants from "../constants/ReduxConstants";

const initialState = {
  visualQueueNotifications: [],
  drawerNotifications: [],
  notificationReceived: false,
  liveOrdersTabCounting: 0,
};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.GET_NOTIFICATION_LIST:
      let notificationReceived = false;
      if (
        (action?.data?.visualQueueNotifications?.length ||
          action?.data?.drawerNotifications?.length) > 0 &&
        action?.isNewOrder
      )
        notificationReceived = true;
      return {
        ...state,
        visualQueueNotifications: action?.data?.visualQueueNotifications,
        drawerNotifications: action?.data?.drawerNotifications,
        notificationReceived,
      };

    case ActionConstants.HEADER_NOTIFICATION_OFF:
      return {
        ...state,
        notificationReceived: false,
      };

    case ActionConstants.LIVE_ORDER_TAB_COUNTING:
      return {
        ...state,
        liveOrdersTabCounting: action?.data?.liveOrderCount,
      };

    default:
      return state;
  }
};
