import * as ActionConstants from "../constants/ReduxConstants";

let initialState = {
  reportsData: {
    docs: [],
    total: 0,
    limit: 15,
    page: 1,
    pages: 1,
  },
};

export const analyticsList = (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.ANALYTICS_LIST:
      return {
        ...state,
        reportsData: {
          ...state.reportsData,
          docs: state.reportsData.docs.concat(
            action.data.docs.filter(
              (value) => !state.reportsData.docs.includes(value)
            )
          ),
          page: action?.data?.page,
          limit: action?.data?.limit,
          pages: action?.data?.pages,
          total: action?.data?.total,
        },
      };

    case ActionConstants.CLEAR_INFINITE_PAGE_DATA:
      return initialState;

    default:
      return state;
  }
};

export const analyticsSummary = (state = [], action) => {
  switch (action.type) {
    case ActionConstants.ANALYTICS_SUMMARY:
      return action.data;

    default:
      return state;
  }
};
